import React from 'react'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import {StaticQuery, graphql} from 'gatsby'

const ThanksPage = () => (
  <StaticQuery
    query={graphql`
      query {
        ThanksPageImage: file(relativePath: { eq: "cityscape-three.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2880, maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <PageHeader image={data.ThanksPageImage.childImageSharp}>
          Thanks For Contacting SCIT
        </PageHeader>
      </Layout>
    )}
  />
)

export default ThanksPage
